@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

*{
    overflow-x: hidden;
        
}

body{
    background-image: url("../assests/Background.png");
    background-size: cover;
  }
.center{
    width: 40%;
}

.centerlogo img{ 
    margin-top: 20px;
    width: 30rem;
    height: 30rem;
    
    
}
.home{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
  
.passbtn {
    
    display: flex;
    height: 50px;
    padding: 15px 37px 18px 36px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 50px;
    background: rgb(93, 26, 210);
    color: var(--White, #FFF);
    text-align: center;
    font-family: 'Poppins';
    font-size: 1.5rem;
    font-style: normal; 
    font-weight: 500;
    line-height: normal;
    border-color: transparent;
    margin-top: 2px;
}
.social{
    font-size: 3.0rem;
    margin-left:840px;
    padding:15px;
    
}



@media screen and (max-width: 950px) {
    .centerlogo img{
    margin-left: 10rem;
    width: 25rem;
    height: 25rem;
    
    margin-right: 7rem;
    }
    
  }
  
  @media screen and (max-width: 438px) {
 .home{
  width:fit-content;
  /* margin-top: 3rem; */
  margin-left:-9rem;
  font-size: 0.7rem;
  
 }
    .centerlogo img{
        
        width: 20rem;
        height: 20rem;
        
        }
        .passbtn{
            margin-top: -1px;
        }
        .social{
          margin-left: 50px;
        }
        
   
  }
  
