body{
  font-family: 'Montserrat', sans-serif;
font-family: 'Plus Jakarta Sans', sans-serif;
font-family: 'Poppins', sans-serif;
}

.btn {
  background-color: #9c27b0;
  color: #fff;
  border: #9c27b0;
}

.btn:hover{
  background-color: #6f287e;
}