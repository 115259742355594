
.row{
    justify-content: center;
    display: flex;
    
    flex-direction: row;
    overflow-x: hidden;
    /* background: #202020 !important;
    background-color: #202020; */
  
    height: 3rem;
    position:relative;
    bottom: 0;
  }
  
  .media p{
    margin-left: 850px;
    font-size: 3.5rem;
    /* width: 490px; */
    
    }
   .media .credits{
    margin-left: 900px;
    
    font-size: 2.5rem;
    margin-top: -90px;
  }

 
  
  