@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap');

.container-event {
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 20px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(35px);
    /* -webkit-backdrop-filter: blur(35px); */
    border: 1px solid rgba(255, 255, 255, 0.3);
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 10px;
}
.container-event h1{
    font-family: 'Poppins';
  color: aliceblue;
  font-weight: 800;
  font-size: 4rem;
  font-weight: 500;
  padding-left: 10%;
  text-align: left;
  text-shadow: 0 20px 50px rgba(201, 194, 194, 0.934);
}
.events-list {

    display: grid;
    grid-template-columns: repeat(3,1fr);
    
    /* flex-flow: row; */
  
    /* grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); */
    row-gap: 4rem;
    column-gap: 2rem;
    
    padding: 8rem;
    /* justify-content: center; */
    align-items: center;
    margin-left:8px ;
    margin-right:9px;
    


}

.card-event {
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    /* overflow: hidden; */   
     height: 28rem;
     width: 20rem;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(35px);
    -webkit-backdrop-filter: blur(20px);
    margin-top: -50px;


}

.card-event a {
    display: block;
    width: 100%;
}

.card-event img {
    width: 300px;
     object-fit: cover;
    border-radius: 10px;
    align-items: center;
    margin:10px;
}
@media only screen and (max-width: 768px) {
    .container-event {
        
        
        height: 100%;
        width: 100;
        margin-left: 8px;
        margin-right: -70px;
       grid-template-columns: repeat(2, 1fr);
    }  
    
    .card-event img{
      width: 150px;
      margin: 10px;
      display: flex;
    }
    
   
    .events-list{
            /* display: flex; */
      margin-left: -144px;
      margin-right: -40px;
      column-gap: 0rem;
      margin-top: -40px;
      grid-template-columns: auto auto;
      

    
    }
  }
  @media only screen and (max-width: 480px) {
    .card-event {
      height: 190px;
      margin-left: 30px;
      margin-right: -40px !important;
           
          padding: 0.5px;
          width: 130px;
          
         /* grid-template-columns: 1fr; */
    }
    .container-event h1{
      align-items: center;
      margin-top: 30px;
      justify-content: center;
      font-size: 3rem;
      margin-left: -15px;
    }   
}