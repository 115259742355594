@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap');
.containerr {
  display: grid;
  grid-template-columns: repeat(auto-fill , 400px);
  column-gap: 150px;
  row-gap: 10px;
}



.card {
  align-items: center;
  margin-top: 100px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  margin-top: 1rem;
  margin-left: 4rem;
  margin-right: 4rem;
  height: auto;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);

}
/* .card:hover{
  box-shadow: 0 40px 40px rgba(238, 236, 236, 0.784), 0.1);
} */

.sponsors{
  /* box-shadow: 0 20px 50px rgba(247, 246, 246, 0.836); */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(35px);
  -webkit-backdrop-filter: blur(35px);
  
  
}


.sponsor {
  font-family: 'Poppins';
  color: aliceblue;
  font-weight: 800;
  font-size: 4rem;
  font-weight: 500;
  padding-left: 3%;
  text-align: left;
  text-shadow: 0 20px 50px rgba(201, 194, 194, 0.934);
}


/* background {
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
} */
.spnsrname{
  padding-top: 1rem;
  font-family: 'Poppins', sans-serif;
  color: aliceblue;
  font-weight: 800;
  font-size: 2.6rem;
  text-align: center;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.sponsor-image{
  width: 50%;
  /* height: 50%; */
  padding: 2rem;
  padding-top: 1rem;
}

.contain {
  padding: 10px 16px;
  text-align: center;
}


@media only screen and (max-width: 768px) {
  .container {
      padding: 0 3.2rem;
      
       grid-template-columns: repeat(2, 1fr);
  }
  .sponsor{
    padding: 0 3.2rem;
    font-size: 2rem;
    text-align: center;
    margin-top: 1rem;
    padding-bottom: 1rem;
  }
}
@media only screen and (max-width: 480px) {
  .container {
    grid-template-columns: repeat(2,1fr);
    height: 100%;
    justify-content: center;
    padding: 0 3.2rem;
    column-gap: normal;
    
    
  }
  .card{
    grid-template-columns: repeat(2,1fr);
    width: 10rem;
  
    margin-left: 70px;
  }
  .sponsor-image{
    height:6rem;
    object-fit:scale-down;
    width: 6rem;
  }
  .spnsrname{
    font-size: 1.5rem;
  }
}
