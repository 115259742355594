
/* general styling */
  @import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@1,500&family=Poppins:wght@800&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@1,700&display=swap');

  
  
  
  
  .content{
    margin-top: 0;
  }
  .timer {
    /* align-items: center; */
    display: flex;
    flex-direction: column;
    
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue",'Montserrat', sans-serif;
      
      
      
  }
  
  .timer {
    color: #fff;
    margin: 0 auto;
    text-align: center;
  }
  
  h1 {
    font-weight: normal;
    letter-spacing: 0.125rem;
    text-transform: uppercase;
    color: #00F0FF;
    text-align: center;
    font-family: 'Montserrat';
    font-size: 35px;
    font-style: normal;
    font-weight: 800;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  
  li {
    display: inline-block;
    font-size: 1.25em;
    list-style-type: none;
    padding: 1em;
    text-transform: uppercase;
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
line-height: normal;
  }
  
  li span {
    display: block;
    font-size: 3rem;
    color: #FFF;
    font-family: 'Poppins';
    font-weight: 700;


  }
  
  .emoji {
    display: none;
    padding: 1rem;
  }
  
  .emoji span {
    font-size: 4rem;
    padding: 0 0.5rem;
  }
  
  @media all and (max-width: 768px) {
    h1 {
      font-size: calc(2rem * var(--smaller));
      font-family: 'Poppins';
    font-weight: 800;
    }
  
    li {
      font-size: calc(1.5rem * var(--smaller));
      font-family: 'Poppins';
    font-weight: 500;
    }
  
    li span {
      font-size: calc(4rem * var(--smaller));
      font-family: 'Poppins';
    font-weight: 700;
    }
  }
  