.team{
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(35px);
  -webkit-backdrop-filter: blur(35px);
  /* margin-top: 0px; */
  }
  .profile{   
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    margin-left: 4rem;
    margin-right: 4rem;
    border-radius: 51px;
  border: 1px solid rgba(255, 255, 255, 0.10);
  background: rgba(138, 138, 138, 0.14);
  backdrop-filter: blur(20px);
    padding:2rem;
  }
  
  
  .subteam{
  font-family: 'Poppins', sans-serif;
  color: aliceblue;
  font-weight: 800;
  font-size: 4rem;
  font-weight: 500;
  padding-left: 3%;
  text-shadow: 0 20px 50px rgba(201, 194, 194, 0.934);
  margin-bottom: 3rem;
  }
  .profile-image {
  
  display: flex;
  justify-content: center;
  
  align-self: center;
  object-fit: cover;
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;
  padding: none;
  margin: 2rem 0 1.5rem 0;
  }
  .container {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 5rem;
    row-gap: 2rem;
  }
  .text{
    color: white;
    text-align: center;
    }