h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-y: hidden;
}
.first-card{
  display: flex;
  flex-wrap: wrap;
}
.profile{
  margin: 2rem;
}
.second-cards{
  display: flex;
  flex-wrap: wrap;
  margin-left: 5%;
}
.tech-content{
  margin: 3%;
}

.team {
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(35px);
  -webkit-backdrop-filter: blur(35px);
  /* margin-top: 0px; */
}
* {
  background: fixed;
  padding: 0;
}
/* tech team */
.techteam {
  width: 450px;
  height: 230px;
  border-radius: 51px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(138, 138, 138, 0.14);
  backdrop-filter: blur(20px);
  display: flex;
  /* flex-direction: column; */
}
.tech-content h4 {
  color: white;
  text-align: center;
  font-size: 0.7rem;
  margin-left: -10px;
}
.img1 {
  width: 200px;
}
.img2 {
  width: 150px;
  position: relative;
  right: 15px;
}
.text {
  color: white;
  text-align: center;
  padding: 23px;
}
.img1 img {
  height: 100px;
  width: 100px;
  border-radius: 100%;
  overflow: hidden;
}
.img2 img {
  height: 100px;
  width: 100px;
  border-radius: 50%;
}
.left {
  display: flex;
  margin-top: 4px;
  justify-content: space-around;
  align-items: center;
}
.right {
  display: flex;
  flex-direction: row-reverse;
  align-items: end;
  justify-content: space-around;
}

.head-image {
  justify-content: right;
  display: flex;
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 50%;
  padding: none;
  margin: 2rem 0 1.5rem 0;
}
.vicehead-image {
  display: flex;
  justify-content: left;
  align-items: left;
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 50%;
  padding: none;
  margin: 2rem 0 1.5rem 0;
}

.HEAD {
  display: flex;
  flex-direction: row;
}
.vice-head {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.profile {
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-left: 4rem;
  margin-right: 4rem;
  border-radius: 51px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(138, 138, 138, 0.14);
  backdrop-filter: blur(20px);
  padding: 2rem;
}

.subteam {
  font-family: "Poppins", sans-serif;
  color: aliceblue;
  font-weight: 800;
  font-size: 4rem;
  font-weight: 500;
  padding-left: 3%;
  text-shadow: 0 20px 50px rgba(201, 194, 194, 0.934);
  margin-bottom: 3rem;
}
.profile-image {
  display: flex;
  justify-content: center;

  align-self: center;
  object-fit: cover;
  width: 300px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;
  padding: none;
  margin: 2rem 0 1.5rem 0;
}
.container {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 5rem;
  row-gap: 2rem;
}
@media only screen and (max-width: 570px) {
  .first-card {
    width: 100%;
    margin-left: 4%;
   
}
  .second-cards{
   
    margin-left: 0;
  }


  .techteam {
    width: 100%;
    height: 210px;
    
  }

  .profile {
    width: 100%;

    margin-bottom: 10px;
    
  }







  /* .profile {
    height: 60%;
  }
  .profile-image img {
    border-radius: 70%;
    width: 50%;
    height: 100%;
  } */
  /* .tech-content{
    display:flex;
    flex-wrap: wrap;
    
        
    margin-top: -110px;
    
  } */
  
  .text {
    overflow-y: hidden;
  }
  .subteam {
    font-size: 2rem !important;
  }
}

@media only screen and (min-width: 900px) {

.first-card{

margin-left: 25%;
}

  /* .container {
    column-gap: 5px !important;
  } */
  /* .team {
    grid-template-columns: repeat(1, 1fr);
    width: 100vw;
  } */

  /* .profile {
    margin-top: 10px;
    width: 130px;
    height: 70%;
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
    column-gap: 3rem;
  } */
  /* .profile h2 {
    font-size: 0.8rem;
  }
  .profile h4 {
    
    font-size: 0.5rem;
    height: 50px;
  }
  .tech-content .techteam{
    width: 100px;
    padding: 1px;   
    
    
  } */


}
