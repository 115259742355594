/* Existing styles */

@import url('https://fonts.googleapis.com/css2?family=Poller+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&family=Poppins:wght@100;200;300;400;500;700&display=swap');
* {
    margin: 0px;
    padding: 0px;
}

.star-icons {
    margin-top: -30px;
    width: 8rem;
    height: 8rem;
    
    
}

.side-pic {
    height: 15rem;
    filter: brightness(50%);
    opacity: 0.7;
}

.content-star {
    display: grid;
    grid-template-columns: auto auto auto;
    overflow:hidden;
}

.image-icon {
    display: flex;
    flex-direction: column;
    
    
    color: aliceblue;
    font-size: 2rem;
    font-weight: 900;
    justify-content: center;
    margin-left: 3rem;
    padding-top: 2rem;
}

.starpage p {
    margin-left: 60rem;
    display: grid;
    grid-template-columns: auto auto auto;
    width: 35rem;
    margin-top: -25rem;
    color: aliceblue;
   
}

.starpage {
    width: 100%;
    height: 100vh;
    position: absolute;
    overflow: hidden;
}

.star-bg {
    width: 100%;
    position: absolute;
    z-index: -1;
    overflow: hidden;
    height:100vh;
}

.pass-button {
    color:white;
    font-size: large;
    justify-content: center;
    border-color: rgb(144, 28, 28);
    border-radius: 30%;
    margin-top: 1rem;
    background-color: rgb(13, 10, 21);
    margin-left: 25px;
    padding: 10px;
    
}



.starpage h1 {
    color: white;
    font-size: 5.5rem;
 
    margin-left: 500px;
    z-index:1000;
    

    font-family: "font-family: 'Poller One', serif;";
}

/* Media Query for mobile screens */
/* Media Query for mobile screens */
@media only screen and (max-width: 768px) {
    .starpage h1 {
        font-size: large;
        display: flex;
        flex-direction: row;
        top: 0; 
        margin-left: 10px; 
        position: absolute; 
        font-weight: 1200;
        font-size: 2rem;
        flex-direction: column; 
        align-items: center;
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    }

    .star-icons {
        width: 3rem; /* Adjust the size for smaller screens */
        height: 3rem; /* Adjust the size for smaller screens */
    }

    .content-star {
        grid-template-columns: auto; /* Stack the content in a single column */
        margin-left: 10px; 
        overflow: hidden;
    }

    .image-icon {
        flex-direction: column;
        margin-left: 10px; /* Adjust the margin as needed */
        margin-top: 18rem;
    }

    .starpage p {
        margin-top: 9rem;
        grid-template-columns: auto; /* Stack the content in a single column */
        width: auto; /* Remove fixed width */
        margin: 10px; /* Adjust the margin as needed */
        text-align: center;
        visibility: hidden; /* Center the text */
    }

    .starpage {
        overflow: hidden;
        background-image: url(../assests/aku.jpg);
        background-size: cover;
    }
    .copyright-text p {
  
        font-size: 1.5rem;
        margin-top: 30px;
        color: #878787;
        
        margin-left: 1rem;
    
      }
}

