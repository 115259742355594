@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap");


.container {
  width: 90%;
  margin: auto;
}



.main-content {
  padding-top: 30px;
  display: flex;
  align-items: center;
}

.malhaar-text {
  width: 80%;
  /* margin-left: -25px; */
  color: aliceblue;
}

.header h1{
  
  color: aliceblue;
  margin-left: -760px;
  margin-top: 50px;
  font-size: 2.5rem;
  /* overflow: hidden; */
  font-family:Arial, Helvetica, sans-serif !important;
}

.second-text {
  padding-left: 30px;
  height: 450px;

  display: flex;
  /* padding: -70px; */
  /* margin-top: 80px; */
  /* margin-left: -200px; */
  /* margin-right: -90px; */
  /* width: 5000px; */
  /* height: 650px; */
   /* width: 100%;
  margin-right: -100px;
  margin-top: -10px;
  margin-left: 90px;
  background-color: white;
  height: 700px; */
} 

.malhaar-text {
padding: 15px;
  font-size: 1.1rem;
}

@media screen and (max-width: 480px) {
  .main-content {
    flex-direction: column-reverse;
  }

  .malhaar-text {
   width: 300px;
   margin-top: -230px;
   font-weight: 500;
   
   
  
    
  }
  .header h1{
    font-size: 1.5rem;
    width: 210px;
    margin-left: -20px;
  }

  .second-text img {
    height: 50%;
    width: 450px;
    margin-left: 30px;
  }
  .container{
  
    margin-top: -50px !important;
    margin-left:-40px;
    width: 480px;

  }
}
